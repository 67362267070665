import React, { useEffect, useState } from "react"
import Button from "../Button"
import Heading from "../Heading"
import "./Hero.scss"

export default function Hero({ btnText, children, text, social, id }) {
  useEffect(() => {
    if (typeof window !== "undefined") {
      import("@dotlottie/player-component")
    }
  }, [])
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    // Function to handle the page load event
    const handleLoad = () => {
      setIsLoaded(true)
    }

    // Add event listener for when the page finishes loading
    window.addEventListener("load", handleLoad)

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("load", handleLoad)
    }
  }, [])

  return (
    <div className="hero-bg" id={id}>
      <div className="container position-relative">
        <div className="row  pb-4 m-0">
          <div className="col-sm-12 col-md-6 hero-text-column p-0 m-0">
            <Heading variant="58" className="font-bold">
              {children}
            </Heading>
            {!!text && <div className="hero-text">{text}</div>}
            <Button text={btnText} variant="fill" href="/contact" />
          </div>
          <div className="col-sm-12 col-md-6 mt-sm-0 mt-5 p-0">
            <div className="hero-img-section">
              <dotlottie-player
                src="/homepage/presenting-analytics.lottie"
                autoplay
                loop
                style={{ width: "100%", margin: 0 }}
              />
            </div>
          </div>
        </div>
        {!!social && (
          <div
            className="social-icons-box"
            data-aos="fade-up"
            data-aos-anchor-placement="bottom-bottom"
            data-aos-duration="1000"
          >
            <div className="social-heading">Follow Us</div>
            <div className="social-icons">
              <a
                href="https://www.linkedin.com/company/codefulcrum/"
                target="_blank"
                className="social-icon"
                rel="noreferrer"
              >
                <img
                  loading="lazy"
                  src="/linkedin.svg"
                  alt="linkedin-icon"
                  className="img-fluid"
                />
              </a>
              <a
                href="https://instagram.com/codefulcrum_?igshid=YmMyMTA2M2Y="
                target="_blank"
                className="social-icon"
                rel="noreferrer"
              >
                <img
                  loading="lazy"
                  src="/instagram.svg"
                  alt="instagram-icon"
                  className="img-fluid"
                />
              </a>
              <a
                href="https://www.facebook.com/codeFulcrum/"
                target="_blank"
                className="social-icon"
                rel="noreferrer"
              >
                <img
                  loading="lazy"
                  src="/facebook.svg"
                  alt="facebook-icon"
                  className="img-fluid"
                />
              </a>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
